<template>
  <div>RaidersLockerRoom</div>
</template>

<script>
export default {
  name: "RaidersLockerRoom"
};
</script>

<style>
</style>